import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';

import { Layout } from '../components/DarkLayout/Layout';
import mdxComponents from './../components/mdxComponents';
import { StyledWrapper, PreviewBlock } from '../components/styles/block.style';

const Block = props => {
  const { mdx } = props.data;

  // meta tags
  const metaTitle = mdx.frontmatter.metaTitle;
  const metaDescription = mdx.frontmatter.metaDescription;

  return (
    <Layout {...props}>
      <Helmet>
        {metaTitle ? <title>{metaTitle}</title> : null}
        {metaTitle ? <meta name="title" content={metaTitle} /> : null}
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
        {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="twitter:description" content={metaDescription} /> : null}
      </Helmet>
      <MDXProvider components={mdxComponents}>
        <StyledWrapper>
          <div className="box">
            <div>
              <div className="header1">{mdx.frontmatter.title}</div>
              <button className="body1 p-2 mb-4 rounded">{mdx.frontmatter.blockNumber}</button>
              <p className="mt-4">
                <strong>{mdx.frontmatter.smallTitle}</strong>
                {mdx.frontmatter.smallTitleText}
              </p>
            </div>
            <div className="box">
              <PreviewBlock>
                <div className="a">
                  <ul>
                    <li className="active tab" id="preview">
                      Preview
                    </li>
                  </ul>
                </div>
                <div className="b">
                  <div className="active tab" id="preview">
                    <img src={mdx.frontmatter.previewImage} alt="blockImage" />
                  </div>
                </div>
              </PreviewBlock>
            </div>
          </div>
        </StyledWrapper>
      </MDXProvider>
    </Layout>
  );
};

export default Block;

export const blockQuery = graphql`
  query BlockBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        metaTitle
        metaDescription
        blockNumber
        smallTitle
        smallTitleText
        previewImage
      }
      fields {
        slug
      }
    }
  }
`;
